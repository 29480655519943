.slide {
    width: 600px;
    height: 400px;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
  }

  .slide_second {
    width: 600px;
    height: 300px;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
  }
  
  .sliderImage_description {
    position: absolute;
    z-index: 100;
    bottom: 30px;
    left: 0px;
    min-width: 100%;
    max-width: 100%;
    background: linear-gradient(to bottom, transparent 0%, black 100%);
  
  }
  
  .sliderImage_description p {
    color: white;
    padding: 3px;
    text-align: center;
  }
  
  .active {
    opacity: 1;
  }
  
  .buttonLeft {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  
  }
  
  .buttonRight {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  
  .buttonRightCross {
    width: 20px;
    height: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 5%;
    right: 10px;
    transform: translateY(-50%);
  }
  
  .dotContainer {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  
  .imageDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid rgb(155, 152, 152);
    margin: 0 5px;
    background: #f1f1f1;
  }
  
  .dotActive {
    background: rgb(82, 81, 81);
  }
  
  .sliderImageNew {
    height: 350px;
    width: 800px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .sliderImageSubNew {
    min-height: 120px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .bgColorImageNewRel {
    position: relative;
    cursor: pointer;
  }
  
  .bgColorImageNewAbs {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.5;
    border-radius: 5px;
  }
  
  @media (min-width: 300px) {
    .sliderContainer {
      width: 280px;
      height: 280px;
      position: relative;
      overflow: hidden;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
    }
    .sliderImage {
      width: 280px;
      height: 280px;
      object-fit: contain;
      border-radius: 5px;
    }
  }
  
  @media (min-width: 500px) {
    .sliderContainer {
      width: 450px;
      height: 450px;
      position: relative;
      overflow: hidden;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
    .sliderImage {
      width: 450px;
      height: 450px;
      object-fit: contain;
      border-radius: 5px;
    }
  }
  
  @media (min-width: 990px) {
    .sliderContainer {
      width: 410px;
      height: 300px;
      position: relative;
      overflow: hidden;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
    .sliderImage {
      width: 410px;
      height: 300px;
      object-fit: contain;
      border-radius: 5px;
    }
  }
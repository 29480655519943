 // todo - beautify this later.
 .sliderimg{
     width: 100%;
     height: 500px;
     object-fit: cover;
}

.sliderimg-height-540{
   width: 100%;
   height: 540px;
   object-fit: cover;
}

.alice-carousel__prev-btn, .alice-carousel__next-btn {
   display:none !important;
}

 .alice-carousel__dots {
  // background-color: black;
   margin-top: -47px !important;
   //z-index: 100;
  // margin-top: 0px !important;
   position: relative;
   height: 47px;
   width: 100%;
   opacity: 0.6;
   left: -3px;
 }

 
 
.head-bar-nav {
    font-size: 16px;
    color: #395B50;
    font-weight: 500;
}

.label-company-name {
    font-size: 16px;
    color: black;
    font-weight: 500;
}

.title-content {
    font-size: 20px;
    color: #395B50;
    font-weight: bold;
}

.label-status-market {
    font-size: 14px;
    color: black;
    font-weight: 400;
}

.text-status-market {
    font-size: 14px;
    color: #00AF54;
    font-weight: 400;
    text-decoration: underline;
}


@media (min-width: 300px) {
    .bg-card-white-new {
        background-color: white !important;
        padding: 20px;
    }

    .padding-form-market {
    }
    .head-bar-back {
        padding-left: 10px;
    }
    .mobile-respo {
        display: inline !important;
    }
    .desktop-respo {
        display: none !important;
    }
}

@media (min-width: 500px) {
    .bg-card-white-new {
        background-color: white !important;
        padding: 20px;
    }
    .padding-form-market {
        padding-left: 40px;
    }
    .head-bar-back {
        padding-left: 10px;
    }
    .mobile-respo {
        display: none !important;
    }
    .desktop-respo {
        display: inline !important;
    }
}

@media (min-width: 990px) {
    .bg-card-white-new {
        background-color: white !important;
        padding: 40px;
    }

    .padding-form-market {
        padding-left: 80px;
    }

    .head-bar-back {
        padding-left: 0px;
    }
    .mobile-respo {
        display: none !important;
    }
    .desktop-respo {
        display: inline !important;
    }
}
@keyframes right {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes left {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.msg-block {
    background-color: white;
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 0px 45px 0px 20px;
    width: 44%;
    overflow-wrap: anywhere;
    /* animation: right 0.55s ease-in-out 1; */
}

.msg-block-tag {
    background-color: white;
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 0px 45px 0px 20px;
    width: 300px;
    overflow-wrap: anywhere;
    /* animation: right 0.55s ease-in-out 1; */
}

.msg-block-admin {
    background-color: white;
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 0px 45px 0px 20px;
    width: 44%;
    overflow-wrap: anywhere;
    /* animation: right 0.55s ease-in-out 1; */
}

.msg-block-logged {
    background-color: #d9fdd3;
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 45px 0px 20px 0px;
    width: 44%;
    margin-left: auto;
    overflow-wrap: anywhere;
    /* animation: left 0.55s ease-in-out 1; */
}

.msg-block-logged-tag {
    background-color: #d9fdd3;
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 45px 0px 20px 0px;
    width: 300px;
    margin-left: auto;
    overflow-wrap: anywhere;
    /* animation: left 0.55s ease-in-out 1; */
}

.dp {
    border-radius: 50%;
    width: 40px;
}

.msg-container {
    margin: -12px 10px 0px 12px;
}

.message {
    font-family: 'Lato', sans-serif;
    color: rgb(47, 47, 47);
    letter-spacing: 0.7px;
    word-spacing: 1.5px;
}

.chat-flex {
    display: flex;
}

.chat-flex-logged {
    display: flex;
    flex-direction: row-reverse;
}

.chat-flex-logged-detail {
    display: flex;
}

.date-time-container {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: end;
    color: rgba(0, 0, 0, 0.3);
}

.date-time-container-logged {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: start;
    color: rgba(0, 0, 0, 0.3);
}

.msg-block-loading {
    background-color: rgb(181 212 241);
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 0px 45px 0px 20px;
    width: 44%;
    overflow-wrap: anywhere;
}

.msg-block-logged-loading {
    background-color: rgb(197 255 156);
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 45px 0px 20px 0px;
    width: 44%;
    margin-left: auto;
    overflow-wrap: anywhere;
}


.left-dp {
    background-color: #9e9ecb !important;
}

.left-msg {
    background-color: #9e9ecb !important;

}

.left-date {
    background-color: #9e9ecb !important;
}

.right-dp {
    background-color: #7bb17f !important;
}

.right-msg {
    background-color: #7bb17f !important;
}

.right-date {
    background-color: #7bb17f !important;
}

@media only screen and (max-width: 500px) {
    .msg-block {
        background-color: white;
        color: black;
        margin-bottom: 20px;
        padding: 8px 15px;
        border-radius: 0px 45px 0px 20px;
        width: 70%;
        overflow-wrap: anywhere;
        /* animation: right 0.55s ease-in-out 1; */
    }

    .msg-block-admin {
        background-color: white;
        color: black;
        margin-bottom: 20px;
        padding: 8px 15px;
        border-radius: 0px 45px 0px 20px;
        width: 70%;
        overflow-wrap: anywhere;
        /* animation: right 0.55s ease-in-out 1; */
    }

    .msg-block-logged {
        background-color: #d9fdd3;
        color: black;
        margin-bottom: 20px;
        padding: 8px 15px;
        border-radius: 45px 0px 20px 0px;
        width: 70%;
        margin-left: auto;
        overflow-wrap: anywhere;
        /* animation: left 0.55s ease-in-out 1; */
    }

    .msg-block-loading {
        background-color: rgb(181 212 241);
        color: black;
        margin-bottom: 20px;
        padding: 8px 15px;
        border-radius: 0px 45px 0px 20px;
        width: 70%;
        overflow-wrap: anywhere;
    }

    .msg-block-logged-loading {
        background-color: rgb(197 255 156);
        color: black;
        margin-bottom: 20px;
        padding: 8px 15px;
        border-radius: 45px 0px 20px 0px;
        width: 70%;
        margin-left: auto;
        overflow-wrap: anywhere;
    }

    .msg-block-tag {
        background-color: white;
        color: black;
        margin-bottom: 20px;
        padding: 8px 15px;
        border-radius: 0px 45px 0px 20px;
        width: 220px;
        overflow-wrap: anywhere;
        /* animation: right 0.55s ease-in-out 1; */
    }

    .msg-block-logged-tag {
        background-color: #d9fdd3;
        color: black;
        margin-bottom: 20px;
        padding: 8px 15px;
        border-radius: 45px 0px 20px 0px;
        width: 220px;
        margin-left: auto;
        overflow-wrap: anywhere;
        /* animation: left 0.55s ease-in-out 1; */
    }
}
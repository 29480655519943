@import './src/vars';

@media (min-width: 300px) {
  .plotMarginMobile {
    margin-bottom: 0.75rem;
  }

  .boxMobile {
    margin:0%;
  }

  .advancedBookingPlotNameMarginMobile {
    margin-top: 1rem;
  }

  .advancedBookingCropNameMarginMobile {
    margin-bottom: 0.5rem;
  }

  .advancedBookingInputMarginMobile{
    width: 11rem;
    padding-right: 0%;
  }

  .advancedBookingUnitWidthMobile{
    width: 7.4rem;
    padding-right: 0%;
  }

  .deliveryChargeWidth{
    width: 3cm;
  }

  .advancedBookingQuantityWidthMobile{
    width: 10.5rem;
    padding-right: 0%;
  }

  .advancedBookingQuantityUnitWidthMobile{
    width: 7rem;
  }

  .advancedBookingDetailsMarginMobile{
    width: 50%;
  }

  .advancedBookingDetailsWidthMobile{
    width: 50%;
  }
}

@media (min-width: 500px) {
  .advancedBookingInputMarginMobile{
    width: 11.7rem;
  }

  .advancedBookingUnitWidthMobile{
    width: 7rem;
  }

  .advancedBookingQuantityWidthMobile{
    width: 12rem;
    padding-right: 0%;
  }

  .advancedBookingDetailsMarginMobile{
    width: 55%;
  }

  .advancedBookingDetailsWidthMobile{
    width: 45%;
  }
}

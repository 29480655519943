.chatroom {
    background-color: #f8f8f8;
    padding-top: 30px;
    /* display: flex; */
    /* flex-direction: column; */

}

.form {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    background-color: #f8f8f8;
}

#msgBox {
    width: 100%;
    background-color: #e4eee4;
    border: 1px solid #77b06e;
    color: black;
    outline: none;
    height: 50px;
}

#msgBox::placeholder {
    color: black;
    letter-spacing: 3px;
    font-style: oblique;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.send-btn {
    width: 60px;
    font-size: 26px;
    background-color: #77b06e;
    color: white;
    border: none;
}
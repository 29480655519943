.modal-location>.modal-dialog {
    /* max-width: 800px !important; */
    height: 600px;
    @media (min-width: 300px) {
        max-width: 500px !important;
    }
    @media (min-width: 900px) {
        max-width: 850px !important;
    }

}
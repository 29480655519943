.head-bar-nav {
    font-size: 16px;
    color: #395B50;
    font-weight: 500;
}
.head-menu:hover {
    font-size: 16px;
    color: #00AF54 !important;
    font-weight: 500;
}

.title-content {
    font-size: 20px;
    color: #395B50;
    font-weight: bold;
}

.bg-form-content {
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    min-height: 490px;
}

.title-farm {
    font-size: 15px;
    color: #787676;
    font-weight: 600;
}

.title-farm-address {
    font-size: 16px;
    color: #395B50;
    font-weight: bold;
}

.title-farm-sub-address {
    font-size: 13px;
    color: #395B50;
    font-weight: normal;
}

.btn-farm-details {
    border-color: #00AF54 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    color: #00AF54 !important;
}

.btn-farm-details:hover {
    color: white !important;
}

.btn-catalog-grade {
    width: 100px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #00AF54 !important;
}

.btn-catalog-grade:hover {
    border-color: #83AEA9 !important;
}

.btn-catalog-grade-selected {
    width: 100px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #00AF54 !important;
    border-color: #00AF54!important;
    border-width: 2px !important;
}


.select-grade {
    border-radius: 8px !important;
    box-shadow: none !important;
    border: 1px solid #00AF54 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
}

.price-sp-label {
    font-size: 15px !important;
    color: #395B50 !important;
    font-weight: 500 !important;
}
.total-sp-label {
    font-size: 20px !important;
    color: #395B50 !important;
    font-weight: bold !important;
}

.avai-detail {
    font-size: 13px !important;
    color: #787676 !important;
    font-weight: normal !important;
    font-style: italic !important;
}

.btn-add-carts {
    width: 100%;
    background-color: #00AF54;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    color: white;
    padding-top: 0.40rem;
    padding-bottom: 0.40rem;
}

.btn-add-carts-disabled {
    width: 100%;
    background-color: #787676;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    color: white;
    padding-top: 0.40rem;
    padding-bottom: 0.40rem;
}

.btn-quantity {
    border-color: #00AF54 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    color: #787676 !important;
    border: 1px solid;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
}

.tag-current-bar {
    color: #00AF54;
}

@media (min-width: 300px) {
    .margin-content {
        padding-left: 20px;
        padding-right: 20px;
        background-color: #F8F8F8 !important;
        padding-bottom: 70px;
    }
}

@media (min-width: 500px) {
    .margin-content {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #F8F8F8 !important;
        padding-bottom: 70px;
    }
}

@media (min-width: 990px) {
    .margin-content {
        padding-left: 100px;
        padding-right: 100px;
        background-color: #F8F8F8 !important;
        height: 92vh;
        padding-bottom: 0;
    }
}

.bg-plot-detail-content {
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-top: 40px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    min-height: 375px;
}
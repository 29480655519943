@import './src/vars';

.loginForm {
  min-width: 330px;
  background: $white-bg;
  border-radius: 10px;

  h1 {
    font-weight: 500;
    font-size: 18px;
    color: $black;
  }

  .inputUserPass {
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid $main-green;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
  }

  input[type="checkbox"] {
    border-radius: 3px;
    box-shadow: none;
  }

  .borderedButton {
    border-radius: 8px;
    border: 1px solid $secondary-black;
  }

  .forgotPassword {
    text-align: right;
    color: $secondary-black;
    font-weight: 400;
  }

  .rememberMe {
    margin-top: 10px;
    color: $secondary-black;

    input {
      border-color: $main-green;
    }
  }

  .submit {
    border-radius: 8px;
    width: 100%;
    background-color: $main-green;
    color: $white;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 30px;
  }

  .titleLogin {
    color: $green-border;
    font-size: 20px;
    font-weight: 700;
  }

  .dontHaveAccount {
    font-size: 12px;
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  
}

.iconNotActiveBuyFar {
  padding: 15px;
  border-radius: 90px;
  background-color: $white;
}

.iconActiveBuyFar {
  padding: 15px;
  border-radius: 90px;
  background-color: $green-border;
}

.iconImgActive {
  filter: brightness(0) invert(1);
}

@media (min-width: 300px) {
  .cardIconAccountTypeWeb {
    display: none;
  }

  .cardIconAccountTypeMobile {
    display: inline;
  }
}

@media (min-width: 500px) {
  .cardIconAccountTypeWeb {
    display: none;
  }

  .cardIconAccountTypeMobile {
    display: inline;
  }
  
}
@media (min-width: 990px) {
  .cardIconAccountTypeWeb {
    display: inline;
  }
  .cardIconAccountTypeMobile {
    display: none !important;
  }
  
}
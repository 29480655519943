@import './src/vars';

.bgFormRegister {
  min-width: 330px;
  background: $white-bg;
  border-radius: 10px;
}

.nextBtnDetail {
  border-radius: 8px;
  width: 100%;
  background-color: $main-green;
  color: $white;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 30px;
}

@media (min-width: 300px) {
  .minWidthProgress {
    min-width: 290px;
  }
}

@media (min-width: 500px) {
  .minWidthProgress {
    min-width: 400px;
  }
  
}
@media (min-width: 990px) {
  .minWidthProgress {
    min-width: 590px;
  }
}



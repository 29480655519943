.chat-popup-float {
    /* display: none; */
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 9;
}

.container-chat {
    width: 450px;
}
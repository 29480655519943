.main-container {
    overflow-x: hidden;
}

.intro {
    position: relative;
    background: #2F5596;
}

.intro::before {
    position: absolute;
    display: block;
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
    height: 100%;
    background: #59966E99;
    content: '';
    opacity: 1;
}

.intro-image {
    object-fit: cover;
}
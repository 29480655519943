@import './src/vars';

.titleActType {
  color: $white !important;
  font-size: 20px;
  font-weight: bold;
}

.bgWhiteCard {
  background-color: $white !important;
}

.titleCard {
  color: $secondary-black !important;
  font-size: 18px;
  font-weight: bold;
}
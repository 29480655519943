/* Hide the scrollbar for the container */
.scroll::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
}

.scroll {
    overflow-x: scroll;
}

.removeLeftBorder {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

@media (max-width: 900px) {
    .footerMobile {
      margin-bottom: 2.5rem;
    }
}

.menuMobile {
    @media (min-width: 300px) {
        display: flex;
        flex-direction: column;
    }

    @media (min-width: 900px) {
        display: flex;
        flex-direction: row;
    }
}
.snackbarContainer {
  position: fixed;
  bottom: 3rem;
  /* left: 1rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  /*background: #323232;*/
  color: #fff;
  z-index: 400;
  margin: 16px;
  border-radius: 4px;
  min-width: 300px;
  max-width: 672px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
  0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  padding: 8px;
  animation: 300ms ease-out forwards slide-up;
  overflow: hidden;
}

.success {
  background: rgb(45, 158, 99);
}

.failure {
  background: rgb(227, 79, 79);
}

.snackbarLabel {
  line-height: 1.2rem;
  width: 100%;
  margin: 0;
  padding: 14px 8px 14px 16px;
}

.snackbarDismiss {
  color: #ae7ee9;
  font-size: 120%;
  font-weight: bold;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  padding: 8px;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@import './src/vars';

@media (min-width: 300px) {
  .header {
    font-size: 30px;
    color: white;
    font-weight: bold;
  }

  .subHeader {
    font-size: 15px;
    color: white;
    font-weight: lighter;
  }
}

@media (min-width: 600px) {
  .header {
    font-size: 45px;
    color: white;
    font-weight: bold;
  }

  .subHeader {
    font-size: 22px;
    color: white;
    font-weight: lighter;
  }
  
}


@media (min-width: 900px) {
  .header {
    font-size: 65px;
    color: white;
    font-weight: bold;
  }

  .subHeader {
    font-size: 32px;
    color: white;
    font-weight: lighter;
  }
  
}


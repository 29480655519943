@import './src/vars';

.formHeader {
  .farmIcon {
    width: 113px;
    height: 130px;
    position: absolute;
    left: 5%;
  }

  .buyerIcon {
    position: absolute;
    height: 78px;
    width: 86px;
    right: 5%;
  }
}

.headerLogin {
  color: $green-border;
  font-size: 20px;
  font-weight: 700;
}

.headerBeBack{
  color: $green-border;
  font-size: 30px;
  font-weight: 700;
}

.headerDate{
  color: $green-border;
  font-size: 20px;
  font-weight: 800;
}

.headerRegister {
  color: $green-border;
  font-size: 50px;
  font-weight: 700;
}

.inputUserPass {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid $main-green !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 13px !important;
}

input[type="checkbox"] {
  border-radius: 3px;
  box-shadow: none;
  border-color: $main-green;
}

.borderedButton {
  border-radius: 8px;
  border: 1px solid $secondary-black;
}

.nextBtnDetail {
  border-radius: 8px;
  width: 100%;
  background-color: $main-green;
  color: $white;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 30px;
}

@media (max-width: 500px) {
  .box {
    width: 18rem;
  }

}
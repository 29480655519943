.div-top {
}

@media (min-width: 300px) {
    .font-header {
        font-size: 35px;
        color: white;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
}

@media (min-width: 500px) {
    .font-header {
        font-size: 50px;
        color: white;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
}

@media (min-width: 990px) {
    .font-header {
        font-size: 65px;
        color: white;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
}


.font-sub-header {
    font-size: 20px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}

.btn-home {
    width: 100%;
    padding: 5px;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
}


.btn-browser-product {
    background-color: #00AF54;
    color: white;
}

.btn-what-is {
    background-color: white;
    color: #00AF54;
    border: 1px solid #00AF54;
}

.top-to-btm{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 90px;
    right: 25px;
    z-index: 9;
  }
  .icon-style{
    background-color: #395B50;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
    border: 2px solid #395B50
  }
  .icon-style:hover{
    animation: none;
    background: #fff;
    color: #395B50;
    border: 2px solid #395B50;
  }
  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  }
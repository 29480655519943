@import './src/vars';

.textHeader {
  color: #395B50;
  ;
}

@media (min-width: 300px) {
  .menuMobile {
    display: inline !important;
  }

  .menuWeb {
    display: none !important;
  }
}

@media (min-width: 500px) {
  .menuMobile {
    display: inline !important;
  }

  .menuWeb {
    display: none !important;
  }
  
}
@media (min-width: 900px) {
  .menuMobile {
    display: none !important;
  }

  .menuWeb {
    display: flex !important;
  }
  
}



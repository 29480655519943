.overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
  }

  .modal {
    position: relative;
    display: flex;
    justify-content: center;
    width: 800px;
    height: 500px; 
  }

  .modal img {
    position: relative;
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.26);
    width: 800px;
    height: 500px;
  }

  .close {
    position: absolute;
    height: 30px;
    top: 6px;
    right: 9px;
    color: rgb(78, 74, 74);
  }

  .close:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .modal {
        width: 600px;
        height: 400px; 
    }
    .modal img {
        width: 600px;
        height: 400px;
      }
  }
@import './src/vars';

.loginForm {
    @media (min-width: 300px){
        min-width: 300px;
        background: $white-bg;
        border-radius: 10px;
    }
    @media (min-width: 500px){
        min-width: 330px;
    }
    h1 {
      font-weight: 500;
      font-size: 18px;
      color: $black;
    }
  
    .borderedButton {
      border-radius: 8px;
      border: 1px solid $secondary-black;
    }
  
    .submit {
      border-radius: 8px;
      width: 100%;
      background-color: $main-green;
      color: $white;
      font-weight: 600;
      font-size: 14px;
      padding: 10px 30px;
    }
  
    .titleLogin {
      color: $green-border;
      font-size: 20px;
      font-weight: 700;
    }
    
  }
  
@media (min-width: 300px) {
  // .arrow {
  //   width: 40px !important;
  // }

  .start{
    display: flex;
    gap: 0.5rem !important;
}
}

@media (min-width: 500px) {
    // .arrow {
    //   width: 100px !important;
    // }

    .start{
        display: flex;
        gap: 1.5rem !important;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.all-chats {
    background-color: #e4eee4;
    width: 90%;
    margin: 0 auto;
    padding: 15px;
    padding-bottom: 60px;
    height: 100vh;
    overflow-y: scroll;
    animation: fade 0.8s ease-in-out 1;
}

.all-chats::-webkit-scrollbar {
    display: none;
}
@import './src/vars';

.menuMobile {
    @media (min-width: 300px){
        display: inline !important;
    }

    @media (min-width: 900px){
        display: none !important;
    }
}

.menuWeb {
    @media (min-width: 300px){
        display: none !important;
    }

    @media (min-width: 900px){
        display: flex !important;
    }
}

.IntroTitle {
    @media (min-width: 300px){
        font-size: 1.875rem;
        line-height: 2.25rem;
    }

    @media (min-width: 600px){
        font-size: 3rem;
        line-height: 1;
    }

    @media (min-width: 900px){
        font-size: 3.75rem;
        line-height: 1;
    }
}

.subTitle{
    @media (min-width: 300px){
        font-size: 1.875rem;
        line-height: 2.25rem;
    }

    @media (min-width: 500px){
        font-size: 3rem;
        line-height: 1;
    }
}

.IntroOne {
    @media (min-width: 300px){
        margin: 10px;
        margin-right: 50px !important;
        margin-left: 50px !important;
        color: white;
        margin-bottom: 50px;
    }

    @media (min-width: 700px){
        margin: 10px;
        margin-right: 150px !important;
        margin-left: 150px !important;
        color: white;
        margin-bottom: 50px;
    }

    @media (min-width: 1280px){
        margin-right: 400px !important;
        margin-left: 400px !important;
    }

    
}

.FooterLogo {
    @media (min-width: 300px){
        height: 1cm;
    }

    @media (min-width: 500px){
        height: 2cm;
    }
}

.FooterDetails {
    @media (min-width: 300px){
        font-size: medium;
        font-weight: 500 !important;
        text-align: center;
    }

    @media (min-width: 700px) {
        font-size: x-large;
        width: 100%;
    }

    @media (min-width: 1280px) {
        font-size: x-large;
        width: 75%;
    }
    
}
@media (min-width: 300px) {
    .minWidthProgress {
      min-width: 290px;
    }
  }
  
@media (min-width: 500px) {
    .minWidthProgress {
      min-width: 400px;
    }
    
}

@media (min-width: 990px) {
    .minWidthProgress {
      min-width: 590px;
    }
}

.mobileText {
  @media (min-width: 300px) {
    font-size: small;
  }
  @media (min-width: 990px) {
    font-size: large;
  }
}

.qrCode {
  position: absolute;
  background: white;
  border-width: 0.1rem;
  border-radius: 0.5rem;
  border-color: black;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  @media (min-width: 300px) {
    margin-top: 0.5rem;
  }
  @media (min-width: 990px) {
    margin-top: -1.25rem;
  }
}

.menuMobile {
  @media (min-width: 400px) {
    display: flex;
  }
  @media (min-width: 500px) {
    display: none;
  }
}
@keyframes right {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes left {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.msg-block-floating {
    background-color: white;
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 0px 45px 0px 20px;
    width: 70%;
    overflow-wrap: anywhere;
    /* animation: right 0.55s ease-in-out 1; */
}

.msg-block-tag-floating {
    background-color: white;
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 0px 45px 0px 20px;
    width: 300px;
    /* animation: right 0.55s ease-in-out 1; */
}

.msg-block-admin-floating {
    background-color: white;
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 0px 45px 0px 20px;
    width: 70%;
    /* animation: right 0.55s ease-in-out 1; */
}

.msg-block-logged-floating {
    background-color: #d9fdd3;
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 45px 0px 20px 0px;
    width: 70%;
    margin-left: auto;
    overflow-wrap: anywhere;
    /* animation: left 0.55s ease-in-out 1; */
}

.msg-block-logged-tag-floating {
    background-color: #d9fdd3;
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 45px 0px 20px 0px;
    width: 300px;
    margin-left: auto;
    /* animation: left 0.55s ease-in-out 1; */
}

.dp-floating {
    border-radius: 50%;
    width: 40px;
}

.msg-container-floating {
    margin: -12px 10px 0px 12px;
}

.message-floating {
    font-family: 'Lato', sans-serif;
    color: rgb(47, 47, 47);
    letter-spacing: 0.7px;
    word-spacing: 1.5px;
}

.chat-flex-floating {
    display: flex;
}

.chat-flex-logged-floating {
    display: flex;
    flex-direction: row-reverse;
}

.chat-flex-logged-detail-floating {
    display: flex;
}

.date-time-container-floating {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: end;
    color: rgba(0, 0, 0, 0.3);
}

.date-time-container-logged-floating {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: start;
    color: rgba(0, 0, 0, 0.3);
}

.msg-block-loading-floating {
    background-color: rgb(181 212 241);
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 0px 45px 0px 20px;
    width: 70%;
}

.msg-block-logged-loading-floating {
    background-color: rgb(197 255 156);
    color: black;
    margin-bottom: 20px;
    padding: 8px 15px;
    border-radius: 45px 0px 20px 0px;
    width: 70%;
    margin-left: auto;
}


.left-dp-floating {
    background-color: #9e9ecb !important;
}

.left-msg-floating {
    background-color: #9e9ecb !important;

}

.left-date-floating {
    background-color: #9e9ecb !important;
}

.right-dp-floating {
    background-color: #7bb17f !important;
}

.right-msg-floating {
    background-color: #7bb17f !important;
}

.right-date-floating {
    background-color: #7bb17f !important;
}
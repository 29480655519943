@import './src/vars';

@media (max-width: 500px) {
  .menuMobile {
    margin-bottom: 50px;
  }

  .footerMobile {
    display: inline !important;
    color: #395B50;
  }
}

@media (min-width: 900px) {
  .footerMobile {
    display: none !important;
  }
  
}

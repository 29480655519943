.title-content-farm {
    font-size: 22px !important;
    font-weight: bold !important;
    color: #395B50 !important;
}

.sub-content-farm {
    font-size: 13px !important;
    color: #787676 !important;
}

.bg-card-fields {
    background-color: #00AF54 !important;
    border-radius: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-width: 65px;
    min-height: 65px;
    color: white;
}
.bg-card-plots {
    background-color: #50B861 !important;
    border-radius: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-width: 65px;
    min-height: 65px;
    color: white;
}
.bg-card-certificate {
    background-color: #EBBC42 !important;
    border-radius: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-width: 65px;
    min-height: 65px;
    color: white;
}

@media (min-width: 300px) {
    .bg-card-white-farm-show {
        background-color: white !important;
        padding: 20px;
        height: 200px;
        margin-bottom: 20px;
    }
}

@media (min-width: 500px) {
    .bg-card-white-farm-show {
        background-color: white !important;
        padding: 20px;
        height: 200px;
        margin-bottom: 20px;
    }
}

@media (min-width: 990px) {
    .bg-card-white-farm-show {
        background-color: white !important;
        padding: 40px;
        padding-top: 30px;
        height: 200px;
    }
}
@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.all-chats-float {
    background-color: #e4eee4;
    width: 100%;
    margin: 0 auto;
    padding: 15px;
    padding-bottom: 60px;
    max-height: 500px;
    overflow-y: scroll;
    animation: fade 0.8s ease-in-out 1;
    font-weight: normal;
}

.all-chats::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-height: 600px) {
    .all-chats-float {
        background-color: #e4eee4;
        width: 100%;
        margin: 0 auto;
        padding: 15px;
        padding-bottom: 60px;
        max-height: 300px;
        overflow-y: scroll;
        animation: fade 0.8s ease-in-out 1;
        font-weight: normal;
    }
}